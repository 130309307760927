@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
}

body {
    align-items: center;
    background-color: rgba(255, 255, 255, 1.0);
    background: url(../../assets/img/bg-splatter.jpg) 0 0;
    background-repeat: repeat;
    background-size: 50%;
    color: rgba(0, 0, 0, 1.0);
    display: flex;
    flex-direction: column;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 300;
}

#root {
    width: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    row-gap: 8vh;
    width: 100%;
}

main {
    align-self: center;
    display: flex;
    flex-direction: column;
    max-width: 1800px;
    row-gap: 15vh;
    width: 100%;
}

p {
    margin: 0 0 4vh 0 ;
}

a {
    border-bottom: 2px solid rgba(0, 0, 0, 1.0);
    color: rgba(0, 0, 0, 1.0);
    text-decoration: none;

    &:hover {
        color: rgba(219, 62, 133, 1.0);
    }
}

.lead-heading {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 14vh;
    z-index: -1;
}