@media (max-width: 960px) {

    main {
        row-gap: 5vh;
    }

    .lead-heading {
        width: 10vw;
    }

    .hero {

        padding-left: 15vw;

        h1, h2, h3, p, p:nth-of-type(1), p:nth-of-type(2), p:nth-of-type(3) {
            margin-left: 0;
        }

        img {
            &.icon-smile {
                top: 45vh;
            }
        }
    }

    .knowledge {
        flex-direction: column;
        padding-left: 15vw;
        row-gap: 4vh;

        img {
            &.icon-swirl {
                bottom: 0;
                width: 150px;
            }
        }
    }

    .contact {
        flex-direction: column;
        row-gap: 4vh;

        form {
            max-width: 100%;
        }
    }
}

@media (max-width: 600px) {
    header {
        &.header {
            .logo {
                font-size: 2rem;
            }

            ul {
                li {
                    img {
                        width: 40px;

                        &.arrow {
                            left: -4rem;
                            top: -2.5rem;
                            transform: rotate(70deg);
                            width: 3rem;
                        }
                    }
                }
            }
        }
    }

    .hero {
        h1 {
            img {
                &.icon-heart {
                    width: 15%;
                }
            }
        }

        img {
            &.icon-smile {
                top: 35vh;
                width: 60px;
            }
        }
    }

    .knowledge {
        .content-right {
            h1 {
                img {
                    &.icon-bright {
                        width: 50px;
                    }
                }
            }
        }

        img {
            &.icon-swirl{
                width: 30%;
            }
        }
    }
}