.hero {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    gap: 4vh;  
    padding: 4vh;
    padding-left: 20vh;
    position: relative;

    h1, h2, h3 {
        line-height: 1.2;
        margin: 0;
    }

    h1 {
        display: inline-block;
        font-size: clamp(3rem, 7vw, 8rem);
        position: relative;
        width: 70vw;

        img {
            &.icon-heart {
                //position: absolute;
                right: 0;
                width: 10%;
            }
        }
    }

    h2 {
        font-size: clamp(2rem, 7vw, 6rem);
        margin-left: 10%;
    }

    h3 {
        font-size: clamp(2rem, 7vw, 6rem);
        margin-left: 20%;
    }

    p {
        font-size: clamp(1.2rem, 1.6vw, 2rem);

        &:nth-of-type(1) {
            margin-left: 10%;
        }

        &:nth-of-type(2) {
            margin-left: 20%;
        }

        &:nth-of-type(3) {
            margin-left: 30%;
        }
    }

    img {
        &.icon-smile {
            left: 0;
            max-width: 10vw;
            position: absolute;
            top: 70vh;
        }
    }
}