footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 0.5vh;
    font-size: 1rem;
    justify-content: center;

    p {
        margin: 0;
    }
}