.knowledge {
    column-gap: 12vh;
    display: flex;
    flex-direction: row;
    padding: 4vh;
    padding-left: 20vh;
    position: relative;

    .content-left {
        display: flex;
        flex-direction: column;
        flex: 1.2;
        padding-top: 2.2vh;
        row-gap: 2vh;

        h2 {
            background-color: rgba(0, 0, 0, 1.0);
            font-size: clamp(2.5rem, 4vw, 2.5rem);
            color: rgba(255, 255, 255, 1.0);
            padding: 2rem;
        }

        ul {
            background-color: rgba(0, 0, 0, 0.05);
            backdrop-filter: blur(5px);
            margin: 0;
            padding: 2rem;

            li {
                align-items: middle;
                border-bottom: 1px solid rgba(0, 0, 0, 1.0);
                display: flex;
                column-gap: 2vh;
                list-style: none;
                padding: 2vh 0;

                &:first-of-type {
                    padding-top: 0;
                }

                &:last-of-type {
                    border-bottom: 0;
                    padding-bottom: 0;
                }

                h3 {
                    color: rgba(219, 62, 133, 1.0);
                    line-height: 1.2;
                }

                p {
                    font-size: 1.2rem;
                    margin: 0;
                }
            }
        }
    }

    .content-right {
        flex: 2;

        h1 {
            font-size: clamp(3rem, 7vw, 8rem);
            line-height: 1.1;
            margin-bottom: 4vh;
            position: relative;

            img {
                &.icon-bright {
                    left: -30px;
                    position: absolute;
                    top: -30px;
                    width: 50px;
                }
            }
        }

        p {
            font-size: clamp(1.2rem, 1.6vw, 2rem);
        }
    }

    img {
        &.icon-swirl{
            bottom: -5vh;
            left: 10vh;
            position: relative;
            width: clamp(140px, 200px, 200px);
            z-index: 1;
        }
    }
}