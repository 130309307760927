header {
    &.header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 1800px;
        padding: 4vh;

        .logo {
            border: 0;
            color: rgba(0, 0, 0, 1.0);
            font-size: 3rem;
            text-decoration: none;
        }

        ul {
            margin: 0;

            li {
                display: flex;
                column-gap: 2vh;
                font-size: 2rem;
                list-style: none;
                position: relative;

                img {
                    display: inline-block;
                    width: 60px;

                    &.arrow {
                        left: -8rem;
                        position: absolute;
                        top: -1.5rem;
                        transform: rotate(45deg);
                        width: 5rem;
                    }
                }

                a {
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }

        .email-block {
            justify-content: end;
        }
    }
}