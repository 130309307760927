.contact {
    background-color: rgba(0, 0, 0, 0.1);
    column-gap: 12vh;
    display: flex;
    flex-direction: row;
    padding: 8vh 4vh;
    position: relative;

    h1 {
        font-size: clamp(3rem, 6vw, 8rem);
        line-height: 1.1;

        img {
            margin-top: 2vh;
        }
    }

    form {
        margin: 0 auto;
        max-width: 50vw;
        width: 100%;

        fieldset {
            border: 0;  
            display: flex;
            flex-direction: column;
            row-gap: 2.5vh;

            input, textarea, button {
                border: 1px solid rgba(0, 0, 0, 1.0);
                border-radius: 0;
                font-family: "Open-Sans", sans-serif;
                font-size: 1.4rem;
                font-weight: 300;
                line-height: 1.2;
                outline: none;
                padding: 1rem 1.5rem;

                &::placeholder {
                    font-family: "Open-Sans", sans-serif;
                }

                &:focus {
                    border: 2px solid rgba(219, 62, 133, 1.0);
                }
            }

            button {
                align-self: end;
                background-color: rgba(219, 62, 133, 1.0);
                border: 0;
                color: rgba(255, 255, 255, 1.0);
                font-weight: 500;
                position: relative;
                width: 50%;

                &:hover {
                    background-color: rgba(234, 71, 152, 1.0);
                    cursor: pointer;
                }

                img {
                    left: -7rem;
                    pointer-events: none;
                    position: absolute;
                    width: 100px;
                }
            }

            .contact-alert {
                background-color: rgba(219, 62, 133, 1.0);
                border: 4px solid rgba(255, 255, 255, 1.0);
                color: rgba(255, 255, 255, 1.0);
                padding: 4vh;

                img {
                    &.icon-smile-contact {
                        display: block; 
                        margin: 0 auto 30px auto;
                        width: 130px;
                    }
                }

                button {
                    background-color: rgba(255, 255, 255, 1.0);
                    color: rgba(219, 62, 133, 1.0);
                    display: flex;
                    justify-content: center;
                    margin: 30px auto 0 auto;

                    &:hover {
                        transform: scale(1.04);
                    }
                }

                p {
                    font-size: clamp(1.2rem, 1.6vw, 2rem);
                    margin: 0;
                    text-align: center;
                }
            }
        }
    }
}